import { env } from '$env/dynamic/public';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseIntegration } from '@supabase/sentry-js-integration';

Sentry.init({
	dsn: env.PUBLIC_SENTRY_DSN,
	environment: env.PUBLIC_LOCALE_STAGE || 'development',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	integrations: [
		replayIntegration(),
		new SupabaseIntegration(SupabaseClient, {
			tracing: true,
			breadcrumbs: true,
			errors: true
		}),
		// @sentry/browser
		Sentry.browserTracingIntegration({
			shouldCreateSpanForRequest: (url) => {
				return !url.startsWith(`${env.PUBLIC_SUPABASE_FRONTEND_URL}/rest`);
			}
		})
	]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
